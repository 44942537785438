<template>
    <div>
        <div class="tabs-content-secondblock">
            <div class="row">
                <div class="col-lg-12">
                    <div class="pt-4 pl-4">
                        <img class="pointer" width="25" @click="goBack()" src="/static/images/back_page_arrow.svg">
                    </div>
                    <div class="ml-2 mr-1 mb-3">
                        <div class="row" v-if="ajax_call_in_progress">
                            <div class="col-lg-12 py-5 px-3 mt-3">
                                <div class="flex-container">
                                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                </div>
                            </div>
                        </div>
                        <div v-else class="row">
                            <div class="col-lg-12">
                                <div class="infoHeader px-3 pb-4 mt-6">
                                    <div class="create-company-header d-flex align-items-center justify-content-between">
                                        <span class="fs-14 text-white">Branch 1</span>
                                        <span class="pointer" @click="expandTabs('bank_info')">
                                            <i class="text-white" :class="{'icon-chevron-double-down':bank_info_expand, 'icon-chevron-double-up':!bank_info_expand}" style="font-size: 20px;"></i>
                                        </span>
                                    </div>
                                    <div v-if="bank_info_expand" style="padding: 0 10px;">
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Bank Name</div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 25px;" v-if="edit_branch_name" >
                                                        <div class="pointer" @click="resetValue('branch_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('branch_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top">
                                                    <input name='branch_name' class="input-field-underline" @focus="editUser('branch_name')" style="padding-top: 6px !important;" :readonly="!edit_branch_name" maxlength="100" v-model="edit_user_data.branch_name">
                                                    </el-tooltip>
                                                    <div v-if="edit_branch_name" class="text-right" >
                                                        <span class="input-field-length">{{ (edit_user_data.branch_name).length }}/100</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Bank Branch Name</div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 25px;" v-if="edit_bank_branch_name" >
                                                        <div class="pointer" @click="resetValue('bank_branch_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('bank_branch_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top">
                                                    <input name='bank_branch_name' maxlength="100" class="input-field-underline" @focus="editUser('bank_branch_name')" style="padding-top: 6px !important;" :readonly="!edit_bank_branch_name" v-model="edit_user_data.bank_branch_name">
                                                    </el-tooltip>
                                                    <div v-if="edit_bank_branch_name" class="text-right" >
                                                        <span class="input-field-length">{{ (edit_user_data.bank_branch_name).length }}/100</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Account Name</div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 25px;" v-if="edit_account_name" >
                                                        <div class="pointer" @click="resetValue('account_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('account_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top">
                                                    <input name='account_name' maxlength="20" class="input-field-underline" @focus="editUser('account_name')" style="padding-top: 6px !important;" :readonly="!edit_account_name" v-model="edit_user_data.account_name">
                                                    </el-tooltip>
                                                    <div v-if="edit_account_name" class="text-right" >
                                                        <span class="input-field-length">{{ (edit_user_data.account_name).length }}/100</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 pb-2">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Account Number *</div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_account_number" >
                                                        <div class="pointer" @click="resetValue('account_number')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('account_number')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top">
                                                    <input name='account_number' v-validate="{required: true, numeric: true, regex: /^\d{9,18}$/}" class="input-field-underline" @focus="editUser('account_number')" style="padding-top: 6px !important;" :readonly="!edit_account_number" maxlength="20" v-model="edit_user_data.account_number">
                                                    </el-tooltip>
                                                    <div class="d-flex justify-content-between align-items-center position-relative" >
                                                        <span class="validation text-danger position-absolute" style="top: 2px;" v-show="errors.has('account_number')">
                                                            {{errors.first('account_number')}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">IFSC Code</div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_ifsc_code" >
                                                        <div class="pointer" @click="resetValue('ifsc_code')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('ifsc_code')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top">
                                                    <input name='ifsc_code' v-validate="'alpha_num'" class="input-field-underline" @focus="editUser('ifsc_code')" style="padding-top: 6px !important;" :readonly="!edit_ifsc_code" maxlength="15" v-model="edit_user_data.ifsc_code">
                                                    </el-tooltip>
                                                    <span class="invalid-feedback-form text-danger" v-show="errors.has('ifsc_code')">
                                                        {{errors.first('ifsc_code')}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Swift Code</div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_swift_code" >
                                                        <div class="pointer" @click="resetValue('swift_code')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('swift_code')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top">
                                                    <input name='swift_code' maxlength="30" v-validate="'alpha_num'" class="input-field-underline" @focus="editUser('swift_code')" style="padding-top: 6px !important;" :readonly="!edit_swift_code" v-model="edit_user_data.swift_code">
                                                    </el-tooltip>
                                                    <span class="invalid-feedback-form text-danger" v-show="errors.has('swift_code')">
                                                        {{errors.first('swift_code')}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>
<script>
    import moment from "moment";
    import {
        HalfCircleSpinner
    } from "epic-spinners";
    import Element from 'element-ui'
    import companies from '../../mixins/companies';
    import { SweetModal } from 'sweet-modal-vue';
    export default {
        props: [],
        components:{
            Element,
            HalfCircleSpinner,
            SweetModal
        },
        data() {
            return {
                edit_user_data: {
                    branch_name: '',
                    bank_branch_name: '',
                    account_name: '',
                    account_number: '',
                    ifsc_code: '',
                    swift_code: '',
                },
                warning_msg: '',
                success_msg: '',
                bank_data: [],
                edit_branch_name: false,
                edit_bank_branch_name: false,
                edit_swift_code: false,
                edit_account_name: false,
                edit_account_number: false,
                edit_ifsc_code: false,
                ajax_call_in_progress: false,
                bank_info_expand: true,
            };
        },
        mixins: [companies],
        methods: {
            moment(date) {
                return moment(date);
            },
            goBack() {
                window.history.back();
            },
            expandTabs(type) {
                if(type == 'bank_info') {
                    this.bank_info_expand = !this.bank_info_expand;
                }
            },
            editUser(obj){
                if(this.loggedInUser.user_role == 'owner' || this.loggedInUser.user_role == 'super admin' || this.loggedInUser.user_role == 'super_admin') {
                    if(obj == 'branch_name'){
                        this.edit_branch_name = true;
                        this.edit_account_name = false;
                        this.edit_bank_branch_name = false;
                        this.edit_swift_code = false;
                        this.edit_account_number = false;
                        this.edit_ifsc_code = false;
                    }
                    else if(obj == 'bank_branch_name'){
                        this.edit_branch_name = false;
                        this.edit_account_name = false;
                        this.edit_bank_branch_name = true;
                        this.edit_swift_code = false;
                        this.edit_account_number = false;
                        this.edit_ifsc_code = false;
                    }
                    else if (obj == 'swift_code') {
                        this.edit_branch_name = false;
                        this.edit_account_name = false;
                        this.edit_bank_branch_name = false;
                        this.edit_swift_code = true;
                        this.edit_account_number = false;
                        this.edit_ifsc_code = false;
                    }
                    else if(obj == 'account_name'){
                        this.edit_branch_name = false;
                        this.edit_account_name = true;
                        this.edit_bank_branch_name = false;
                        this.edit_swift_code = false;
                        this.edit_account_number = false;
                        this.edit_ifsc_code = false;
                    }
                    else if(obj == 'account_number'){
                        this.edit_branch_name = false;
                        this.edit_account_name = false;
                        this.edit_bank_branch_name = false;
                        this.edit_swift_code = false;
                        this.edit_account_number = true;
                        this.edit_ifsc_code = false;
                    }
                    else if(obj == 'ifsc_code'){
                        this.edit_branch_name = false;
                        this.edit_account_name = false;
                        this.edit_bank_branch_name = false;
                        this.edit_swift_code = false;
                        this.edit_account_number = false;
                        this.edit_ifsc_code = true;
                    }
                }
            },
            resetValue(obj){
                let bank_data = JSON.parse(localStorage.getItem('bank_details_info'));
                if(obj == 'branch_name'){
                    this.edit_branch_name = false;
                    this.edit_user_data.branch_name = bank_data.bank_name == undefined || null ? '' : bank_data.bank_name;
                }
                else if(obj == 'bank_branch_name'){
                    this.edit_bank_branch_name = false;
                    this.edit_user_data.bank_branch_name = bank_data.bank_branch_name == undefined || null ? '' : bank_data.bank_branch_name;
                }
                else if (obj == 'swift_code') {
                    this.edit_swift_code = false;
                    this.edit_user_data.swift_code = bank_data.swift_code == undefined || null ? '' : bank_data.swift_code;
                }
                else if(obj == 'account_name'){
                    this.edit_account_name = false;
                    this.edit_user_data.account_name = bank_data.account_name == undefined || null ? '' : bank_data.account_name;
                }
                else if(obj == 'account_number'){
                    this.edit_account_number = false;
                    this.edit_user_data.account_number = bank_data.account_number == undefined || null ? '' : bank_data.account_number;
                }
                else if(obj == 'ifsc_code'){
                    this.edit_ifsc_code = false;
                    this.edit_user_data.ifsc_code = bank_data.ifsc_code == undefined || null ? '' : bank_data.ifsc_code;
                }
            },

            async getBankDataById() {
                this.edit_branch_name = false;
                this.edit_account_name = false;
                this.edit_bank_branch_name = false;
                this.edit_swift_code = false;
                this.edit_account_number = false;
                this.edit_ifsc_code = false;
                this.ajax_call_in_progress = true;
                try {
                    let params = {
                        company_id: this.$route.query.bank,
                        skip: 0,
                        limit: 10
                    }
                    let response = await this.getBankDetailsById(params, );
                    if(response.status_id == 1) {
                        localStorage.setItem('bank_details_info', JSON.stringify(response.response));
                        let data = response.response;
                        this.bank_data = response.response;
                        this.edit_user_data.branch_name = data.bank_name;
                        this.edit_user_data.bank_branch_name = data.bank_branch_name;
                        this.edit_user_data.account_name = data.account_name;
                        this.edit_user_data.account_number = data.account_number;
                        this.edit_user_data.ifsc_code = data.ifsc_code;
                        this.edit_user_data.swift_code = data.swift_code;
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                    this.ajax_call_in_progress = false;
                }
            },

            async updateInfo(obj) {
                try {
                    let params = {
                    };
                    if(obj == 'account_number'){
                        params.account_number = this.edit_user_data.account_number;
                        this.$validator.validateAll().then(async result => {
                                if (result == true) {
                                    let response = await this.updateBankDetailsById(params, this.$route.query.bank);
                                    if(response.status_id == 1) {
                                        this.success_msg = response.message
                                        this.$refs.success_modal.open();
                                        setTimeout(() => {
                                            this.$refs.success_modal.close();
                                            this.getBankDataById();
                                        }, 2000);
                                    }
                                }
                            })
                    } else {
                        if(obj == 'branch_name'){
                            params.bank_name = this.edit_user_data.branch_name;
                        }
                        else if(obj == 'bank_branch_name'){
                            params.bank_branch_name = this.edit_user_data.bank_branch_name;
                        }
                        else if (obj == 'swift_code') {
                            params.swift_code = this.edit_user_data.swift_code;
                        }
                        else if(obj == 'account_name'){
                            params.account_name = this.edit_user_data.account_name;
                        }
                        else if(obj == 'ifsc_code'){
                            params.ifsc_code = this.edit_user_data.ifsc_code;
                        }
                        let response = await this.updateBankDetailsById(params, this.$route.query.bank);
                        if(response.status_id == 1) {
                            this.success_msg = response.message
                            this.$refs.success_modal.open();
                            setTimeout(() => {
                                this.$refs.success_modal.close();
                                this.getBankDataById();
                            }, 2000);
                        }
                    }
                }
                catch(err) {
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                }
            }

        },
        props: [],
        mounted() {
            this.getBankDataById();
        },
        created() {
            const dict = {
                custom: {
                    bank_name: {
                        alpha_spaces:() => "Bank Name should be alphabetical",
                    },
                    bank_branch_name: {
                        alpha_spaces:() => "Bank Branch Name should be alphabetical",
                    },
                    account_name: {
                        alpha_spaces:() => "Account Name should be alphabetical",
                    },
                    account_number:{
                        required:() => "Account number is required",
                        regex:() => "Account number must be atleast 9 characters long and can't be greater than 18 characters.",
                        numeric:() => "Account Number should be numeric",
                    },
                    ifsc_code:{
                        alpha_num:() => "IFSC code must be alphanumeric.",
                    },
                    swift_code:{
                        alpha_num:() => "SWIFT code must be alphanumeric.",
                    },
                }
            }
            this.$validator.localize('en', dict)
        },
        watch: {
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
        },
        beforeDestroy() {
            localStorage.removeItem('bank_details_info');
        },
    };
</script>
<style scoped>
    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
        margin-left: -20px;
        margin-right: -15px;
    }
</style>